import React, { useEffect, useState, useRef } from "react";
import classes from "./reports.module.css";
import Navbar from "../Navbar/Navbar";
import camera from "../Images/camera.png";
import list from "../Images/list.png";
import grid from "../Images/grid.png";
import PdfIcons from "./PdfIcons";
import axios from "axios";
import pdficon from "../Images/pdficon.png";
import { json, useNavigate } from "react-router-dom";
import SignOut from "../SignOut/SignOut";
import dropdownIcon from "../Images/drop.png";
import searchbutton from "../Images/searchbutton.png";
import userdata from "../typesofusers.json";
import Papa from "papaparse";
import csvFile from "../../src/data_to_import.xlsx";
import * as XLSX from "xlsx";

const Reports1 = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState([]);
  const [cont, setCont] = useState(false);
  const [totThatMember, settotThatMember] = useState(0);
  const [number, setnumber] = useState("");
  const [searched, setsearched] = useState(false);
  const [showdata, setshowdata] = useState([]);
  const [alldata, setalldata] = useState([]);
  const [allendusers, setallendusers] = useState([]);
  const [allapollousers, setallapollousers] = useState([]);
  const [noalldata, setnoalldata] = useState(null);
  const [totenduser, settotenduser] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [name, setname] = useState("");
  const [totaltests, settotaltests] = useState();

  const [csvData, setCsvData] = useState([]);

  const [jsonData, setjsonData] = useState();

  var allKnownData = [];

  const searchInputRef = useRef(null);

  var accno = localStorage.getItem("accno");
  console.log("inside demo reports", accno);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setsearched(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function search(phone) {
    if (!accno || !phone) return setshowdata([]);
    if (phone.length < 10) {
      setshowdata([]);
      return alert("please provide a valid 10 digit number");
    }
    axios
      .get("https://www.iterve.in/apollouser1/fetchVitalsDataForNumber", {
        params: { accno: accno, phone: phone },
      })
      .then((res) => {
        if (res.data.message === "No phone user found") {
          setshowdata([]);
          setnumber("");
          return alert("No vitals found for the given number");
        } else {
          setshowdata(res.data[0].vitalsData);
        }
      });
  }

  function allusersadmin() {
    console.log("allusers", allapollousers, allendusers);
    for (let i = 0; i < allapollousers.length; i++) {
      if (
        allapollousers[i].accountNumber !== "a" &&
        allapollousers[i].accountNumber !== "s" &&
        allapollousers[i].accountNumber !== "demo"
      ) {
        for (let j = 0; j < allapollousers[i].vitalsData.length; j++) {
          allKnownData.push(allapollousers[i].vitalsData[j]);
        }
      }
    }
    for (let i = 0; i < allendusers.length; i++) {
      allKnownData.push(allendusers[i]);
    }
    // console.log(allKnownData);
    setshowdata(allKnownData);
    setdata(allKnownData);
  }

  useEffect(() => {
    if (!accno) return;
    if (localStorage.getItem("accno") === "SA_Sub") {
      axios
        .get("https://www.iterve.in/apollouser1/vitalsDataSize", {
          params: { accno: "SA_Master" },
        })
        .then((res) => {
          // console.log(res.data)
          // getalltests(accno);
          setDataCount(res.data.vitalsDataSize);
          if (accno !== "a" && accno !== "s" && accno !== "demo") {
            settotThatMember(res.data.vitalsDataSize);
          }
          setname("SA_Sub");
        });
    } else if (userdata.form2user && userdata.form2user.includes(accno)) {
      axios
        .get("https://www.iterve.in/apollouser1/vitalsDataSize", {
          params: { accno },
        })
        .then((res) => {
          // console.log(res.data)
          // getalltests(accno);
          setDataCount(res.data.vitalsDataSize);
          if (accno !== "a" && accno !== "s" && accno !== "demo") {
            settotThatMember(res.data.vitalsDataSize);
          }
          setname(res.data.username);
        });
    } else if (userdata.form3user && userdata.form3user.includes(accno)) {
      axios
        .get("https://www.iterve.in/palmuser/vitalsDataSize", {
          params: { accno },
        })
        .then((res) => {
          // console.log(res.data);
          // getalltests(accno);
          setDataCount(res.data.vitalsDataSize);
          if (accno !== "a" && accno !== "s" && accno !== "demo") {
            settotThatMember(res.data.vitalsDataSize);
          }
          setname(res.data.username);
        });
    } else if (userdata.facePalmUser && userdata.facePalmUser.includes(accno)) {
      axios
        .get("https://www.iterve.in/facepalmuser/vitalsDataSize", {
          params: { accno },
        })
        .then((res) => {
          // console.log(res.data);
          // getalltests(accno);
          setDataCount(res.data.vitalsDataSize);
          if (accno !== "a" && accno !== "s" && accno !== "demo") {
            settotThatMember(res.data.vitalsDataSize);
          }
          setname(res.data.username);
        });
    }
    if (accno && accno.startsWith("admin")) {
      allusersadmin();
    }
  }, [allapollousers, allendusers]);

  useEffect(() => {
    var a = 0;

    if (accno && accno.startsWith("admin")) {
      axios
        .get("https://www.iterve.in/apollouser1/all")
        .then((res) => {
          console.log(res.data);

          for (let i = 0; i < res.data.length; i++) {
            if (
              res.data[i].accountNumber !== "a" &&
              res.data[i].accountNumber !== "s" &&
              res.data[i].accountNumber !== "demo"
            ) {
              a = a + res.data[i].vitalsData.length;
            }
          }
          setallapollousers(res.data);
          // console.log(a);
          setnoalldata(a);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("https://www.iterve.in/enduser/all")
        .then((res) => {
          // console.log(res.data);
          // console.log(res.data.length);
          settotenduser(res.data);
          setallendusers(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get("https://www.iterve.in/apollouser1/allcount")
        .then((res) => setnoalldata(res.data.totalVitalsData));

      axios
        .get("https://www.iterve.in/enduser/allcount")
        .then((res) => {
          // console.log("enduserCount", res.data.cnt);
          settotenduser(res.data.cnt);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function convertDateFormat(dateString) {
    const parts = dateString.split("-");
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    const date = new Date(year, month - 1, day);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[date.getMonth()];
    const newDateFormat = monthName + " " + day + ", " + year;

    return newDateFormat;
  }

  const pdfContainerSize = () => {
    const dropId = document.getElementById("dropId");
    const pdfcontainer = document.getElementById("pdfcontainer");
    if (cont === false || window.innerWidth < 768) {
      pdfcontainer.style.width = "90vw";
      dropId.style.transform = "scaleY(-1)";
    } else {
      pdfcontainer.style.width = "55vw";
      dropId.style.transform = "scaleY(1)";
    }
    console.log("cont", cont);
  };

  function convertAndDownloadCSV(arrayString) {
    // Parse the array string
    const array = JSON.parse(arrayString);

    // Create CSV content
    let csvContent = "Time,Red Mean,Green Mean,Blue Mean\n";

    // Iterate through the array
    array.forEach((innerArray, index) => {
      // Construct CSV rows
      const row = `${index * 33},${innerArray[0]},${innerArray[1]},${
        innerArray[2]
      }\n`;
      csvContent += row;
    });

    // Create a Blob object with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);

    // Set the file name
    a.download = "rgbRoute1.csv";

    // Append the anchor element to the body
    document.body.appendChild(a);

    // Simulate click on the anchor to trigger download
    a.click();

    // Remove the anchor from the body
    document.body.removeChild(a);
  }

  useEffect(() => {
    if (
      localStorage.getItem("accno") !== "SA_Sub" &&
      localStorage.getItem("accno") !== "SA_Master"
    ) {
      // Only call pdfContainerSize when cont is true
      pdfContainerSize();
    }
  }, [cont]);
  const dropFunc = () => {
    setCont(!cont);
  };

  return (
    <>
      {localStorage.getItem("accno") !== "SA_Sub" ? (
        <div className={classes.container}>
          <Navbar />
          <div className={classes.heading}>
            <div className={classes.circle}></div>
            <div className={classes.texts}>
              <div className={classes.text1}>Welcome,</div>
              <div className={classes.text2}>{name}</div>
            </div>
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <img src={camera} className={classes.camera} />
            ) : (
              <></>
            )}
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <div
                className={classes.newbutton}
                onClick={() => {
                  if (
                    accno === "Guest" ||
                    accno === "Test_Dosha" ||
                    accno === "HHJamSaheb"
                  ) {
                    navigate("/selftest2", { state: "fromApolloMember" });
                  } else if (
                    userdata.form2user &&
                    userdata.form2user.includes(accno)
                  ) {
                    navigate("/selftest1", { state: "fromApolloMember" });
                  } else if (
                    userdata.form3user &&
                    userdata.form3user.includes(accno)
                  ) {
                    navigate("/selftestpalm", { state: "fromApolloMember" });
                  } else if (
                    userdata.facePalmUser &&
                    userdata.facePalmUser.includes(accno)
                  ) {
                    navigate("/selftestFacePalm", {
                      state: "fromApolloMember",
                    });
                  } else {
                    navigate("/selftest", { state: "fromApolloMember" });
                  }
                }}
              >
                New Test
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.pdfhead}>
            {localStorage.getItem("accno") !== "SA_Sub" && (
              <div className={classes.prevreports}>Previous Reports</div>
            )}
            {/* <img src={list} className={classes.listicon} />
        <img src={grid} className={classes.listicon} /> */}
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <div className={classes.search} ref={searchInputRef}>
                <div className={classes.searchbar}>
                  {searched ? (
                    <>
                      <input
                        type="text"
                        onChange={(e) => setnumber(e.target.value)}
                        className={classes.inputsearch}
                        value={number}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            search(number);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <img
                    src={searchbutton}
                    className={classes.listicon}
                    onClick={() => {
                      setsearched((val) => !val);
                      search(number);
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <div
                className={classes.assessmentHeadContainer}
                onClick={dropFunc}
              >
                <div className={classes.dropElement}>
                  <div className={classes.assesmentText}>
                    Assessment Counter
                  </div>
                  <img
                    src={dropdownIcon}
                    className={classes.dropdownIcon}
                    id="dropId"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={classes.assesmentText1}>
                  Tests Performed:{totThatMember}
                </div>
              </>
            )}
          </div>
          {showdata ? (
            <div>
              <div className={classes.pdfAssessmentContainer}>
                <div className={classes.pdfcontainer} id="pdfcontainer">
                  {dataCount > 0 && accno !== undefined && (
                    <PdfIcons
                      data={data}
                      showdata={showdata}
                      navigate={navigate}
                      accno={accno}
                      dataCount={dataCount}
                    />
                  )}
                </div>
                {cont ? (
                  <div className={classes.assessmentContainer}>
                    <div className={classes.dropElement}>
                      <div className={classes.dropText}>
                        Test Record by {name}:
                      </div>
                      {accno.startsWith("admin") ? (
                        <div className={classes.countText}>-</div>
                      ) : (
                        <div className={classes.countText}>{totThatMember}</div>
                      )}
                    </div>
                    <div className={classes.dropElement}>
                      {accno.startsWith("admin") ? (
                        <div className={classes.dropText}>
                          Test Record by other 12 users:
                        </div>
                      ) : (
                        <div className={classes.dropText}>
                          Test Record by other users:
                        </div>
                      )}
                      {accno.startsWith("admin") ? (
                        <div className={classes.countText}>-</div>
                      ) : (
                        <div className={classes.countText}>
                          {noalldata - totThatMember}
                        </div>
                      )}
                    </div>
                    <div className={classes.dropElement}>
                      <div className={classes.dropText}>
                        Test Record by End User:
                      </div>
                      {accno.startsWith("admin") ? (
                        <div className={classes.countText}>-</div>
                      ) : (
                        <div className={classes.countText}>{totenduser}</div>
                      )}
                    </div>
                    <div className={classes.dropElement}>
                      <div className={classes.dropText}>
                        Test Record by Iterve Teams:
                      </div>
                      <div className={classes.countText}>
                        {noalldata + totenduser}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.signoutSec}>
                <SignOut />
              </div>
            </div>
          ) : (
            <div className={classes.signoutSec}>
              <SignOut />
            </div>
          )}
          {/* <button onClick={findrecord}>Find</button> */}
        </div>
      ) : (
        <>
          <div className={classes.container}>
            <Navbar />
            <div className={classes.heading}>
              <div className={classes.circle}></div>
              <div className={classes.texts}>
                <div className={classes.text1}>Welcome,</div>
                <div className={classes.text2}>{name}</div>
              </div>
            </div>
            <div className={classes.pdfhead}>
              {localStorage.getItem("accno") !== "SA_Sub" && (
                <div className={classes.prevreports}>Previous Reports</div>
              )}
              {/* <img src={list} className={classes.listicon} />
        <img src={grid} className={classes.listicon} /> */}
              {localStorage.getItem("accno") !== "SA_Sub" ? (
                <div className={classes.search} ref={searchInputRef}>
                  <div className={classes.searchbar}>
                    {searched ? (
                      <>
                        <input
                          type="text"
                          onChange={(e) => setnumber(e.target.value)}
                          className={classes.inputsearch}
                          value={number}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              search(number);
                            }
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <img
                      src={searchbutton}
                      className={classes.listicon}
                      onClick={() => {
                        setsearched((val) => !val);
                        search(number);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {localStorage.getItem("accno") !== "SA_Sub" ? (
                <div
                  className={classes.assessmentHeadContainer}
                  onClick={dropFunc}
                >
                  <div className={classes.dropElement}>
                    <div className={classes.assesmentText}>
                      Assessment Counter
                    </div>
                    <img
                      src={dropdownIcon}
                      className={classes.dropdownIcon}
                      id="dropId"
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {showdata ? (
              <div>
                <div className={classes.pdfAssessmentContainer}>
                  <div id="pdfcontainer"></div>

                  {cont ? (
                    <div className={classes.assessmentContainer}>
                      {localStorage.getItem("accno") === "SA_Sub" ? (
                        <div className={classes.dropElement}>
                          <div className={classes.dropText}>
                            Test Record by {name}:
                          </div>
                          {accno.startsWith("admin") ? (
                            <div className={classes.countText}>-</div>
                          ) : (
                            <div className={classes.countText}>
                              {totThatMember}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className={classes.dropElement}>
                            <div className={classes.dropText}>
                              Test Record by {name}:
                            </div>
                            {accno.startsWith("admin") ? (
                              <div className={classes.countText}>-</div>
                            ) : (
                              <div className={classes.countText}>
                                {totThatMember}
                              </div>
                            )}
                          </div>
                          <div className={classes.dropElement}>
                            {accno.startsWith("admin") ? (
                              <div className={classes.dropText}>
                                Test Record by other 12 users:
                              </div>
                            ) : (
                              <div className={classes.dropText}>
                                Test Record by other users:
                              </div>
                            )}
                            {accno.startsWith("admin") ? (
                              <div className={classes.countText}>-</div>
                            ) : (
                              <div className={classes.countText}>
                                {noalldata - totThatMember}
                              </div>
                            )}
                          </div>
                          <div className={classes.dropElement}>
                            <div className={classes.dropText}>
                              Test Record by End User:
                            </div>
                            {accno.startsWith("admin") ? (
                              <div className={classes.countText}>-</div>
                            ) : (
                              <div className={classes.countText}>
                                {totenduser}
                              </div>
                            )}
                          </div>
                          <div className={classes.dropElement}>
                            <div className={classes.dropText}>
                              Test Record by Iterve Teams:
                            </div>
                            <div className={classes.countText}>
                              {noalldata + totenduser}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.testRow}>
                  <div className={classes.miniCont}>
                    <div className={classes.testCont}>
                      Test Performed - {totThatMember}
                    </div>
                  </div>
                  <div className={classes.miniCont}>
                    <div
                      className={classes.testBtn}
                      onClick={() => {
                        if (
                          accno === "Guest" ||
                          accno === "Test_Dosha" ||
                          accno === "HHJamSaheb"
                        ) {
                          navigate("/selftest2", { state: "fromApolloMember" });
                        } else if (
                          userdata.form2user &&
                          userdata.form2user.includes(accno)
                        ) {
                          navigate("/selftest1", { state: "fromApolloMember" });
                        } else if (
                          userdata.form3user &&
                          userdata.form3user.includes(accno)
                        ) {
                          navigate("/selftestpalm", {
                            state: "fromApolloMember",
                          });
                        } else {
                          navigate("/selftest", { state: "fromApolloMember" });
                        }
                      }}
                    >
                      New Test
                    </div>
                  </div>
                </div>
                <div className={classes.signoutSec}>
                  <SignOut />
                </div>
              </div>
            ) : (
              <div className={classes.signoutSec}>
                <SignOut />
              </div>
            )}
            {/* <button onClick={findrecord}>Find</button> */}
          </div>
        </>
      )}
    </>
  );
};

export default Reports1;
