import React, { useState, useRef, useEffect } from "react";
import classes from "./reportVitals.module.css";
import apolloLogo from "../Images/apolloLogo.png";
import aivotLogo from "../Images/aivotLogo 2.png";
import watermark from "../Images/watermark.png";
import HiimsLogo from "../Images/HIIMSLogo.png";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink, View, pdf } from "@react-pdf/renderer";
import MyPDF from "./myPDF";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import wpimage from "../Images/whatsapp.png";
import mailimage from "../Images/mail.png";
import appleimage from "../Images/apple.png";
import androidimage from "../Images/android.png";
import webimage from "../Images/www.png";
import copyimage from "../Images/copy.png";
import copydone from "../Images/done.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tickimage from "../Images/tick.png";
import cross from "../Images/remove.png";
import gamil from "../Images/gmail.png";
import yahoo from "../Images/yahoo.png";
import outlook from "../Images/outlook.png";
import userdata from "../typesofusers.json";
import Hiims_icon from "../Images/Hiims_icon.png";
import BJP_icon from "../Images/BJP_icon.png";

const ReportsPalm = () => {
  const mypdfRef = useRef(null);
  const [isDownloaded, setDownloaded] = useState(null);
  const [blobUrl, setBlobUrl] = useState("");
  const [healthParameters, sethealthParameters] = useState([]);
  const [doshaParameters, setdoshaParameters] = useState([]);
  const [doshacombinations, setdoshacombinations] = useState();
  const [formData, setFormData] = useState({});
  const [vitals, setvitals] = useState();
  const [childid, setchildid] = useState("");
  const [parentid, setparentid] = useState("");
  const [sharepopup, setsharepopup] = useState(false);
  const [whatsappopt, setwhatsappopt] = useState(false);
  const [mailopt, setmailopt] = useState(false);
  const [copycompleted, setcopycompleted] = useState(false);
  const [accountNumber, setaccountNumber] = useState("");
  const [enduserid, setenduserid] = useState("");
  const [ecgPlot, setEcgPlot] = useState();
  const [ecgImage, setEcgImage] = useState();
  const [fullBase64String, setFullBase64String] = useState();
  const [mealrecommended, setmealrecommended] = useState({});
  console.log("vitals page rendered");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.state);
    console.log(parentid, childid);

    if (location.state) {
      setvitals(location.state);
      console.log(location.state);
      setenduserid(location.state.id);
      console.log(localStorage.getItem("accno"));
      var accno = localStorage.getItem("accno");
      if (!accno) {
        accno = "";
      }
      setaccountNumber(accno);
      if (accno !== "" && accno !== "enduser") {
        console.log("accno exists", accno);
        var findid = "";
        console.log("before report vitals", accno);
        const dbcollection =
          userdata.form3user &&
          userdata.form3user.includes(localStorage.getItem("accno"))
            ? "palmuser"
            : userdata.facePalmUser.includes(localStorage.getItem("accno"))
            ? "facepalmuser"
            : "apollouser";
        console.log("user id geting fetched from", dbcollection, "collection");
        axios
          .get(`https://www.iterve.in/${dbcollection}/getuserid`, {
            params: { accno },
          })
          .then((res) => {
            findid = res.data;
            console.log(res.data);
            setparentid(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
        setchildid(location.state._id);
      } else {
        setparentid("enduser");
        setchildid(location.state.id);
      }
      // fetch user Id as object id and set it
    } else {
      console.log("else");
      const objectId = location.pathname.split("/")[2].slice(4);
      const vitalsDataId = location.pathname.split("/")[3].slice(4);
      setparentid(objectId);
      setchildid(vitalsDataId);
      console.log(parentid, childid);
      if (!objectId || !vitalsDataId) navigate("/reports");
      const dbcollection = objectId === "enduser" ? "enduser" : "apollouser";
      fetch(
        `https://www.iterve.in/${dbcollection}/fetchSingleVital?objectId=${objectId}&vitalDataId=${vitalsDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setvitals(objectId === "enduser" ? res : res.vitalsData);
          setaccountNumber(res.accountNumber ? res.accountNumber : "");
        });
    }
  }, [location.state]);

  useEffect(() => {
    // setmealrecommended(dietRecommendations[doshacombinations.toLowerCase()]);
    const fetchDietRecommendation = async () =>
      await axios
        .get("https://www.iterve.in/facepalmuser/dietRecommendation", {
          params: { category: doshacombinations },
        })
        .then((res) => {
          console.log(res);
          setmealrecommended(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    doshacombinations && fetchDietRecommendation();
  }, [doshacombinations]);

  useEffect(() => {
    console.log(doshacombinations);
    console.log(mealrecommended);
  }, [mealrecommended]);

  useEffect(() => {
    console.log(vitals);

    if (vitals) {
      setdoshacombinations(vitals["doshaCombination"]);

      const malesBelow18 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "70 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "60 - 75",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "14 - 17",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40 - 54",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 100",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Serum Creatinine*",
          value: vitals["serumCreatinine"],
          unit: "mg/dl",
          referenceRange: "0.8-1.5",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const malesBetween18And45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50 - 65",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "12 - 20",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "13 - 17",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40 - 52",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 100",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Serum Creatinine*",
          value: vitals["serumCreatinine"],
          unit: "mg/dl",
          referenceRange: "0.8-1.5",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const malesAbove45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50 - 65",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "13 - 17",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40 - 52",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 100",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Serum Creatinine*",
          value: vitals["serumCreatinine"],
          unit: "mg/dl",
          referenceRange: "0.8-1.5",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const femalesBelow18 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "70 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "60 - 75",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "10 - 20",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "12 - 15",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "36 - 46",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 100",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Serum Creatinine*",
          value: vitals["serumCreatinine"],
          unit: "mg/dl",
          referenceRange: "0.8-1.5",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const femalesBetween18And45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50 - 65",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "15 - 25",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "12 - 15",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "36 - 46",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 100",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Serum Creatinine*",
          value: vitals["serumCreatinine"],
          unit: "mg/dl",
          referenceRange: "0.8-1.5",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const femalesAbove45 = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "bpm",
          referenceRange: "60 - 100",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "80 – 200 ms",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: "70 – 150 ms",
        },
        {
          vital: "SDSD",
          value: vitals["SDSD"],
          unit: "-",
          referenceRange: "-",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "ms",
          referenceRange: "600 – 1200",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: "60 – 140 ms",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "70 – 180 ms",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "<27",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m²",
          referenceRange: "2.5 – 4.0",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "ml",
          referenceRange: "60 - 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "60 - 80",
        },
        {
          vital: "MAP",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "30 - 50",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m²",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "45 - 60",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "20 - 30",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "5 - 15",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "20 - 30",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "mil/uL",
          referenceRange: "4.5 - 5.9",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg",
          referenceRange: "27 - 32",
        },
        {
          vital: "Mean Corpuscular Volume*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "12 - 15",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "36 - 46",
        },
        {
          vital: "Random Blood Sugar*",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 100",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4.0 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 140",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "<100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: ">40",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "<30",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "<150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "<200",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15-73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "-",
          referenceRange: "<40",
        },
        {
          vital: "Serum Creatinine*",
          value: vitals["serumCreatinine"],
          unit: "mg/dl",
          referenceRange: "0.8-1.5",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dl",
          referenceRange: "3.5-8.5",
        },
      ];

      const palmbuser = [
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "Beats per min",
          referenceRange: "55 - 100",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        {
          vital: "Breathing Rate",
          value: vitals["breathing_rate"],
          unit: "BPM",
          referenceRange: "12 – 20",
        },
        {
          vital: "Pulse Rate Quotient",
          value: vitals["pulse_rate_quotient"],
          unit: "Beats per min",
          referenceRange: "4",
        },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "Kg/m^2",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: "50% - 60%",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: "M < 24, F < 31",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "1 - 12",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: "M 8-25, F 20-35",
        },
        {
          vital: "Cardiac Output",
          value: vitals["cardiac_output"],
          unit: "L/min",
          referenceRange: "5-6",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L(min.m^2) ",
          referenceRange: "2.5-4",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "milli litre",
          referenceRange: "50-100",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "0 – 100",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "90 – 120",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "< 90",
        },
        {
          vital: "Mean Arterial Pressure",
          value: vitals["mean_atrial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "40 – 60",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "< 100",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: "40-60",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "< 150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "< 200",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "5 - 40",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "million cell/mcl",
          referenceRange: "4.5 – 5.5",
        },
        {
          vital: "Mean Corpuscular Value*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "83.0 – 101.0",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg/cells",
          referenceRange: "27.0 - 32.0",
        },
        {
          vital: "Blood Volume",
          value: vitals["bloodVolume"],
          unit: "mL",
          referenceRange: "5000",
        },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange: "13.0 – 17.0",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: "40.0 - 50.0",
        },
        {
          vital: "Random Blood Sugar",
          value: localStorage.getItem("truerbs"),
          unit: "mg/dl",
          referenceRange: "70 – 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "< 5.7%",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "70 - 100",
        },
        {
          vital: "SGPT*",
          value: vitals["SGPT"],
          unit: "U/L",
          referenceRange: "21-72",
        },
        {
          vital: "SGOT*",
          value: vitals["SGOT"],
          unit: "U/L",
          referenceRange: "17-59",
        },
        {
          vital: "GGPP*",
          value: vitals["GGPP"],
          unit: "U/L",
          referenceRange: "15 - 73",
        },
        {
          vital: "Fatty Liver Index*",
          value: vitals["fattyLiverIndex"],
          unit: "FLI",
          referenceRange: "<30",
        },
        {
          vital: "Serum Creatinine*",
          value: vitals["serumCreatinine"],
          unit: "mg/dL",
          referenceRange: "0.5-1.7",
        },
        {
          vital: "Uric Acid*",
          value: vitals["uricAcid"],
          unit: "mg/dL",
          referenceRange: "3.5-7.2",
        },

        // {
        //   vital: "T3 (Triiodothyronine)",
        //   value: "",
        //   unit: "ng/dl",
        //   referenceRange: "80-200",
        // },
        // {
        //   vital: "T4 (Thyroxine)",
        //   value: "",
        //   unit: "μg/dL",
        //   referenceRange: "4.8 – 12.7",
        // },
        // {
        //   vital: "TSH (Thyroid Stimulating Hormone)",
        //   value: "",
        //   unit: "μIU/mL",
        //   referenceRange: "0.54 – 5.30",
        // },

        // {
        //   vital: "Allowable Blood Loss",
        //   value: vitals["allowable_blood_loss"],
        //   unit: "milli litres",
        //   referenceRange: "< 14",
        // },
      ];

      setEcgPlot(vitals["ecgplot"]);
      if (
        localStorage.getItem("accno") === "Jintur1" ||
        localStorage.getItem("accno") === "Jintur2"
      ) {
        sethealthParameters(palmbuser);
      } else {
        if (vitals["gender"] === "Male" && parseInt(vitals["age"]) < 18) {
          sethealthParameters(malesBelow18);
        } else if (
          vitals["gender"] === "Male" &&
          parseInt(vitals["age"]) >= 18 &&
          parseInt(vitals["age"]) <= 45
        ) {
          sethealthParameters(malesBetween18And45);
        } else if (
          vitals["gender"] === "Male" &&
          parseInt(vitals["age"]) > 45
        ) {
          sethealthParameters(malesAbove45);
        } else if (
          vitals["gender"] === "Female" &&
          parseInt(vitals["age"]) < 18
        ) {
          sethealthParameters(femalesBelow18);
        } else if (
          vitals["gender"] === "Female" &&
          parseInt(vitals["age"]) >= 18 &&
          parseInt(vitals["age"]) <= 45
        ) {
          sethealthParameters(femalesBetween18And45);
        } else if (
          vitals["gender"] === "Female" &&
          parseInt(vitals["age"]) > 45
        ) {
          sethealthParameters(femalesAbove45);
        }
      }

      setdoshaParameters([
        {
          vital: "Vata",
          percentage: Math.round(parseFloat(vitals["vata"])),
          balance: vitals["vatabalance"],
        },
        {
          vital: "Pitta",
          percentage: Math.round(parseFloat(vitals["pitta"])),
          balance: vitals["pittabalance"],
        },
        {
          vital: "Kapha",
          percentage: Math.round(parseFloat(vitals["kapha"])),
          balance: vitals["kaphabalance"],
        },
      ]);

      console.log(vitals);
      setFormData({
        name: vitals["name"],
        phoneNumber: vitals["phoneNumber"],
        email: vitals["email"],
        subjectid: vitals["subjectid"],
        gender: vitals["gender"],
        age: vitals["age"],
        height: vitals["height"],
        weight: vitals["weight"],
      });
    }
  }, [vitals]);

  const tableHead = ["Name", "Subject ID", "Gender", "Age", "Height", "Weight"];

  useEffect(() => {
    if (ecgPlot) {
      setFullBase64String(`data:image/png;base64,${ecgPlot}`);
    }
  }, [ecgPlot]);

  useEffect(() => {
    console.log("ecgImage", ecgImage);
  }, [ecgImage]);

  const [recipientEmail, setRecipientEmail] = useState("aniroycr537@gmail.com");
  const [file, setFile] = useState(null);

  const handleMailButtonClick = async (prov) => {
    try {
      // Generate PDF blob

      // console.log(parentid,childid);

      if (prov === "gmail") {
        var link = `https://mail.google.com/mail/u/0/?fs=1&to&su=check+out+the+health+vitals&body=https://www.iterve.in/vitals/pid%3D${parentid}/cid%3D${childid}&tf=cm`;
      } else if (prov === "yahoo") {
        var link = `https://compose.mail.yahoo.com/?subject=check+out+the+health+vitals&body=https://www.iterve.in/vitals/pid%3D${parentid}/cid%3D${childid}`;
      } else {
        var link = `https://outlook.office.com/mail/deeplink/compose/?subject=check%20out%20the%20health%20vitals&body=https://www.iterve.in/vitals/pid%3D${parentid}/cid%3D${childid}`;
      }
      window.open(link, "_blank");
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  // const handleMailButtonClick = async () => {
  //   // Fetching the PDF file
  //   const blob = await pdf(<MyPDF healthParameters={healthParameters} formData={formData} />).toBlob();
  //   console.log('blob', blob);
  //   // Converting the PDF file to base64
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = () => {
  //       const base64data = reader.result.split(',')[1];

  //       // Generate mailto link
  //       const recipient = 'recipient@gmail.com';
  //       const subject = 'Your Subject';
  //       const body = 'Please find the attached PDF.';
  //       const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(base64data)}`;

  //       // Open mail app
  //       console.log(mailtoUrl);
  //       window.open(mailtoUrl, '_blank');
  //   };
  // };

  const copyToClipboard = async (text) => {
    console.log(text);
    await navigator.clipboard
      .writeText(text)
      .then(() => {
        setcopycompleted(true);
        setTimeout(() => {
          setcopycompleted(false);
        }, 3000);
        toast.success("Text copied to clipboard!", {
          autoClose: 1500,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleWhatsappClick = async (s) => {
    //whatsapp share

    const sharelink = `https://${s}.whatsapp.com/send?text=https://www.iterve.in/vitals/pid=${parentid}/cid=${childid}`;
    // const a = document.createElement('a');
    // a.href=sharelink;
    // a.click();
    window.open(sharelink, "_blank");
  };

  function formatNumber(num) {
    // Check if the number is an integer
    if (Number.isInteger(num)) {
      return num;
    } else {
      // Format the number to 2 decimal places
      return num.toFixed(2);
    }
  }

  const vitalsTableData = vitals
    ? [
        {
          name: "Vata",
          valueX: Math.round(parseFloat(vitals["vata"])),
          valueY: vitals["vatabalance"],
        },
        {
          name: "Pitta",
          valueX: Math.round(parseFloat(vitals["pitta"])),
          valueY: vitals["pittabalance"],
        },
        {
          name: "Kapha",
          valueX: Math.round(parseFloat(vitals["kapha"])),
          valueY: vitals["kaphabalance"],
        },
      ]
    : [];

  return (
    <div className={classes.container}>
      {/* <img src={watermark} className={classes.watermark} /> */}
      <div className={classes.headingRow}>
        <div style={{ width: "15vw" }}>
          <img
            src={Hiims_icon}
            alt="Hiims Logo here"
            className={
              accountNumber
                ? accountNumber === "Jintur1" || accountNumber === "Jintur2"
                  ? classes.logo1
                  : classes.logo1VisOff
                : classes.logo1VisOff
            }
          />
        </div>
        {localStorage.getItem("accno") !== "Jintur1" &&
        localStorage.getItem("accno") !== "Jintur2" ? (
          <div className={classes.headingText}>Your Vitals</div>
        ) : (
          <div className={classes.headingText}>
            <div className={classes.headingTextUp}>
              मा.आमदार मेघना साकोरे बोर्डीकर
            </div>
            <div className={classes.headingTextDown}>
              भारतीय जनता पक्ष (भाजप)
            </div>
          </div>
        )}
        {localStorage.getItem("accno") === "Jintur1" ||
        localStorage.getItem("accno") === "Jintur2" ? (
          <div style={{ width: "15vw" }}>
            <img
              src={BJP_icon}
              alt="BJP Logo here"
              className={classes.logoBJP}
            />
          </div>
        ) : (
          <div style={{ width: "15vw" }}>
            <img
              src={aivotLogo}
              alt="Aivot Logo here"
              className={classes.logo}
            />
          </div>
        )}
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.name}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Name:</div>

          <div>{formData.name}</div>
        </div>

        <div
          className={classes.email}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Email:</div>
          <div>{formData.email}</div>
        </div>
      </div>

      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>PhoneNumber</div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Subject ID</div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Gender</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Age</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Height</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div>Weight</div>
        </div>
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.phoneNumber} </div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.subjectid} </div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.gender}</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.age}</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.height}</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.weight}</div>
        </div>
      </div>

      <div className={classes.vitalRows}>
        <div className={classes.vitalsCol}>
          <div className={classes.vital}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Vitals
            </div>
          </div>
          <div className={classes.value}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Values
            </div>
          </div>
          <div className={classes.units}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Units
            </div>
          </div>
          <div className={classes.range}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Reference Range
            </div>
          </div>
        </div>
        {healthParameters.map((param, index) => (
          <div className={classes.vitalsCol} key={index}>
            <div className={classes.vital}>
              <div>{param.vital}</div>
            </div>
            <div className={classes.value}>
              <div>{param.value}</div>
            </div>
            <div className={classes.units}>
              <div>{param.unit}</div>
            </div>
            <div className={classes.range}>
              <div>{param.referenceRange}</div>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.tableheading}>Ayurvedic Tridoshas</div>
      {vitals && (
        <table className={classes.vitalsTable}>
          <thead>
            <tr>
              <th>Dosha</th>
              <th>Prakruti</th>
              <th>Vikruti</th>
            </tr>
          </thead>
          <tbody>
            {vitalsTableData.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td>{row.valueX}</td>
                <td>{row.valueY}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className={classes.tableheading}>Food Recommendations</div>
      {vitals && (
        <table className={classes.vitalsTable}>
          <thead>
            <tr>
              <th>Food Type</th>
              <th>Eat</th>
              <th>Avoid</th>
              <th>Occasional</th>
            </tr>
          </thead>
          <tbody>
            {mealrecommended?.Eat &&
              Object.keys(mealrecommended.Eat).map((foodType, index) => (
                <tr key={index}>
                  <td>{foodType}</td>
                  <td>{mealrecommended.Eat[foodType]?.join(", ") || ""}</td>
                  <td>{mealrecommended.Avoid?.[foodType]?.join(", ") || ""}</td>
                  <td>
                    {mealrecommended.Occasionally?.[foodType]?.join(", ") || ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <img src={fullBase64String} className={classes.ecgPlot} />

      <div className={classes.noteSec}>
        <div>
          <b>Note:</b> These values are only indicative, cannot be used for
          clinical. The vitals marked as <b>* (asterisk)</b> are under
          evaluation for testing the models.
        </div>
      </div>

      <div className={classes.allbuttons}>
        <button
          className={classes.downloadBtn}
          onClick={() => {
            navigate("/reports1");
          }}
        >
          Home
        </button>

        <PDFDownloadLink
          document={
            <MyPDF
              healthParameters={healthParameters}
              formData={formData}
              accno={accountNumber}
              ecgPlot={fullBase64String}
              doshaParameters={doshaParameters}
              mealrecommended={mealrecommended}
            />
          }
          fileName={`${vitals ? vitals["name"] : ""}_report.pdf`}
          className={classes.downloadBtn}
        >
          {({ loading }) => (loading ? "Loading..." : "Download now")}
        </PDFDownloadLink>

        <div className={classes.popparent}>
          {sharepopup ? (
            <>
              <div className={classes.sharepopup}>
                <img
                  src={cross}
                  className={classes.crossbtn}
                  onClick={() => setsharepopup(false)}
                />
                <div className={classes.largecont}>
                  {!whatsappopt ? (
                    <button
                      className={
                        !mailopt ? classes.sharebtn : classes.sharebtn2
                      }
                      onClick={() => {
                        setmailopt(!mailopt);
                        return false;
                      }}
                    >
                      <img src={mailimage} className={classes.wpimage}></img>
                    </button>
                  ) : (
                    <></>
                  )}
                  {mailopt ? (
                    <>
                      <div className={classes.expandArrow}></div>
                      <div className={classes.wpoptdiv}>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("gmail")}
                        >
                          <img src={gamil} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("yahoo")}
                        >
                          <img src={yahoo} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("outlook")}
                        >
                          <img src={outlook} className={classes.optimage}></img>
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {!mailopt && (
                    <div className={classes.wpdiv}>
                      {!whatsappopt ? (
                        <button
                          className={classes.sharebtn}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      ) : (
                        <button
                          className={classes.sharebtn2}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      )}
                      {whatsappopt ? (
                        <>
                          <div className={classes.expandArrow}></div>
                          <div className={classes.wpoptdiv}>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("web")}
                            >
                              <img
                                src={webimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={androidimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={appleimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
                <div className={classes.smallcont}>
                  <div
                    className={classes.copybtn}
                    onClick={() =>
                      copyToClipboard(
                        `https://www.iterve.in/doshavitals/pid=${parentid}/cid=${childid}`
                      )
                    }
                  >
                    <img
                      src={copycompleted ? tickimage : copyimage}
                      className={classes.copyimage}
                    ></img>
                    {`Copy`}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* <button
            className={classes.downloadBtn}
            onClick={() => {
              setsharepopup(!sharepopup);
            }}
          >
            Share
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ReportsPalm;
