import React, { useState } from "react";
import classes from "./registerUser.module.css";
import faceLogo from "../Images/faceLogo.png";
import Navbar from "../Navbar/Navbar";
import axios from "axios";

const RegisterUser = ({ setTokenGen }) => {
  const fields = [
    { label: "Name", name: "name" },
    { label: "Company Email", name: "email" },
    { label: "Contact Number", name: "phoneNumber" },
  ];

  const [formData, setFormData] = useState({});

  // Function to update form data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { name, empid, email, phoneNumber } = formData;

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Regular expression for phone number validation (basic)
    const phoneRegex = /^\d{10}$/;

    // Check if name is provided
    if (!name) {
      alert("Please enter your name.");
      return false;
    }

    // Check if email is provided and valid
    if (!email || !emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    // Check if phone number is provided and valid
    if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
      alert("Please enter a valid 10-digit phone number.");
      return false;
    }

    return true; // Form is valid
  };

  const handleSubmit = () => {
    const submitReg = document.getElementById("submitReg");
    localStorage.setItem("page", "enduser");
    console.log(formData);
    if (!validateForm()) return;
    else {
      submitReg.style.backgroundColor = "grey";
      submitReg.style.cursor = "not-allowed";
      submitReg.style.pointerEvents = "none";
    }

    if (
      formData.email.slice(-9) !== "@aivot.ai" &&
      formData.email.slice(-9) !== "@aivot.in"
    ) {
      alert("Please enter a valid company email address.");
      submitReg.style.backgroundColor = "black";
      submitReg.style.cursor = "pointer";
      submitReg.style.pointerEvents = "auto";
      return;
    }

    axios
      .post("https://www.iterve.in/token", formData)
      .then((response) => {
        console.log(response.data);
        console.log(
          `https://www.iterve.in/selftestFacePalm/token=${response.data.token}`
        );
        alert("Please, check your email. The link has been sent successfully");
        fields.map((field, index) => {
          const inputTag = document.getElementById(field.name);
          inputTag.value = "";
          inputTag.innerText = "";
          setFormData({});
          return field;
        });
        submitReg.style.backgroundColor = "black";
        submitReg.style.cursor = "pointer";
        submitReg.style.pointerEvents = "auto";
        const firstInputTag = document.getElementById(fields[0].name);
        firstInputTag.focus();
        console.log(setTokenGen);
        setTokenGen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className={classes.container}>
      <Navbar />
      <div className={classes.content}>
        <div className={classes.left}>
          <div className={classes.heading}>Submit Your Details</div>
          {fields.map((field, index) => (
            <div key={index} className={classes.lableNameWrap}>
              <div className={classes.label}>{field.label}:</div>
              <input
                name={field.name}
                id={field.name}
                className={classes.inputfield}
                onChange={handleInputChange}
                autoComplete="off"
              />
            </div>
          ))}
          <button className={classes.btn} onClick={handleSubmit} id="submitReg">
            Submit
          </button>
        </div>
        <div className={classes.right}>
          <img src={faceLogo} alt="apolloLogo" className={classes.faceLogo} />
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
